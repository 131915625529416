import Vue, { inject, onScopeDispose } from 'vue';
import type { EventMap } from './events';

// not exported, should be wrapped with a composable
const widgetEventBus = new Vue();

const EVENT_NAME = 'widgetEventEmitted';
export type EventListener<K> = {
  name: K;
  widgetCode: string | null;
};

/// emit an event
///
/// const roleAddedEvent = useEmitWidgetEvent('role-added');
///
/// roleAddedEvent('data');
export function useEmitWidgetEvent<K extends keyof EventMap>(name: K) {
  const widgetCode = inject('widgetCode', null);
  const emitFn = (data: EventMap[K]) => {
    widgetEventBus.$emit(EVENT_NAME, {
      name,
      data,
      widgetCode,
    });
  };

  return emitFn;
}

/// listen to one event
///
/// onWidgetEvent('role-added', () => {refresh});
/// onWidgetEvent('rows-selected', onGroupSelected, 'GROUP_SELECTION_TABLE')
/// onWidgetEvent({name: 'role-added', widgetCode: null}, 'MY_GENERIC_TABLE', () => {refresh});
export function onWidgetEvent<K extends keyof EventMap>(
  forEvent: EventListener<K>,
  callback: (data: EventMap[K]) => void,
) {
  const wrappedCallback = (event: {
    name: string;
    widgetCode: string | null;
    data: unknown;
  }) => {
    const matchedEventName = event.name === forEvent.name;
    const matchedWidgetCode =
      forEvent.widgetCode === null || forEvent.widgetCode === event.widgetCode;
    if (matchedEventName && matchedWidgetCode) {
      callback(event.data as EventMap[K]);
    }
  };

  widgetEventBus.$on(EVENT_NAME, wrappedCallback);

  onScopeDispose(() => {
    widgetEventBus.$off(EVENT_NAME, wrappedCallback);
  });
}

/// listen to multiple emits
///
/// Examples:
/// const connect = useConnectWidgetEvents();
///
/// connect([{name: 'role-added', widgetCode: 'BLAH'}], () => {refresh})
export function useConnectWidgetEvents() {
  return <K extends keyof EventMap>(
    events: K[] | EventListener<K>[],
    fn: (data: EventMap[K]) => void,
  ) => {
    events.forEach((toConnect) => {
      if (typeof toConnect === 'string') {
        onWidgetEvent(
          {
            name: toConnect,
            widgetCode: null,
          },
          fn,
        );
      } else {
        onWidgetEvent(toConnect, fn);
      }
    });
  };
}
