
import { RplDocumentLink } from '@dpc-sdp/ripple-document-link';
import RplIcon from '@dpc-sdp/ripple-icon';
import { formatBytes } from '@/helpers/file';
import Vue from 'vue';
import Confirm from '@/components/Confirm.vue';
import Uppy, { UppyFile } from '@uppy/core';
import { poll } from '@/api/poll';
import { useEmitWidgetEvent } from '@/components/composables/events/useWidgetEvents';
import { isSuccessfulUpload } from './resourceUppy';

type Data = {
  confirmCancel: boolean;
  scanning: boolean;
  infected: boolean;
  fileUploadError: boolean;
  cancelPolling: boolean;
};
type Computed = {
  queued: boolean;
  uploading: boolean;
  complete: boolean;
  error: boolean;
  fileSizeString: string;
  progress: number;
  extension: string;
};
type Methods = {
  handleFileCancel: () => void;
  handleFileRemoved: (
    file: UppyFile<Record<string, unknown>, Record<string, unknown>>,
  ) => void;
  onCancelUpload: () => void;
  onCancelCancelUpload: () => void;
  onConfirmCancelUpload: () => void;
  onFileRemove: () => void;
  onInfectedRemove: () => void;
};
type Props = {
  upload: UppyFile & { error?: string };
  uppy: Uppy;
  attachmentIdKey: string; // Key to fetch attachmentId
  resourceIdKey: string; // Key to fetch resourceId
  fetchAttachmentApi: (
    entityId: string,
    attachmentId: string,
  ) => Promise<Record<string, unknown>>;
  deleteAttachmentApi: (
    entityId: string,
    attachmentId: string,
  ) => Promise<Record<string, unknown>>;
};
type SetupBinding = {
  attachmentCompleteEvent: (attachmentId: string) => void;
};

export default Vue.extend<Data, Methods, Computed, Props, SetupBinding>({
  name: 'Upload',
  components: { RplDocumentLink, RplIcon, Confirm },
  props: {
    upload: { type: Object, required: true },
    uppy: { type: Object, required: true },
    attachmentIdKey: { type: String, required: true },
    resourceIdKey: { type: String, required: true },
    fetchAttachmentApi: {
      type: Function,
      required: true,
      validator: (fn) => typeof fn === 'function',
    },
    deleteAttachmentApi: {
      type: Function,
      required: true,
      validator: (fn) => typeof fn === 'function',
    },
  },
  data() {
    return {
      confirmRemove: false,
      confirmCancel: false,
      scanning: false,
      infected: false,
      fileUploadError: false,
      cancelPolling: false,
    };
  },
  mounted() {
    // Listen for Uppy cancel-all event
    this.uppy.on('cancel-all', this.handleFileCancel);
    // Listen for file-removed event specifically for this file
  },
  beforeDestroy() {
    // Clean up event listener
    this.uppy.off('cancel-all', this.handleFileCancel);
  },
  setup() {
    const attachmentCompleteEvent = useEmitWidgetEvent('attachment-complete');
    return { attachmentCompleteEvent };
  },
  computed: {
    queued() {
      return !this.uploading && !this.complete && !this.error;
    },
    uploading() {
      return !this.complete && this.upload.progress?.uploadStarted !== null;
    },
    complete() {
      return this.upload.progress?.uploadComplete ?? false;
    },
    error() {
      return !!this.upload.error;
    },
    fileSizeString() {
      return formatBytes(this.upload.size);
    },
    progress() {
      return this.upload.progress?.percentage || 0;
    },
    extension() {
      return this.upload.name.substring(this.upload.name.lastIndexOf('.') + 1);
    },
  },
  methods: {
    async handleFileCancel() {
      this.cancelPolling = true; // Stop polling

      // Ensure the upload is valid and successful
      if (!isSuccessfulUpload(this.upload)) {
        this.onFileRemove();
        return;
      }

      // Access xhrUpload after confirming the upload is valid
      const attachmentId =
        this.upload?.xhrUpload?.headers?.[this.attachmentIdKey];
      const resourceId = this.upload?.xhrUpload.headers[this.resourceIdKey];

      if (attachmentId && resourceId) {
        try {
          // Make an API call to delete the attachemnt upload
          await this.deleteAttachmentApi(resourceId, attachmentId);
        } catch (error: any) {
          console.error(`Failed to delete attachment: ${error.message}`);
        }
      } else {
        console.warn('Attachment ID or Resource ID is missing.');
      }
    },
    handleFileRemoved(file) {
      if (file.id === this.upload.id) {
        this.cancelPolling = true; // Stop polling for this file

        const attachmentId =
          this.upload?.xhrUpload?.headers?.[this.attachmentIdKey];
        const resourceId =
          this.upload?.xhrUpload?.headers?.[this.resourceIdKey];
        if (attachmentId && resourceId) {
          this.deleteAttachmentApi(resourceId, attachmentId)
            .then(() =>
              console.debug(`Deleted attachment with ID: ${attachmentId}`),
            )
            .catch((error) =>
              console.error(`Failed to delete attachment: ${error.message}`),
            );
        }
      }
    },
    onCancelUpload() {
      this.confirmCancel = true;
    },
    onCancelCancelUpload() {
      this.confirmCancel = false;
    },
    onConfirmCancelUpload() {
      // Call handleFileRemoved with the current file
      this.handleFileRemoved(this.upload);
      // this happens during upload, add reason so we know to delete
      this.uppy.removeFile(this.upload.id, 'removed-by-user');
      this.confirmCancel = false;
    },
    onFileRemove() {
      // this happens prior to clicking upload, no reason
      this.uppy.removeFile(this.upload.id);
    },
    async onInfectedRemove() {
      if (!isSuccessfulUpload(this.upload)) {
        this.onFileRemove();
        return;
      }

      const attachmentId = this.upload?.xhrUpload.headers[this.attachmentIdKey]; // Dynamic attachmentId key
      const entityId = this.upload?.xhrUpload.headers[this.resourceIdKey];
      await this.deleteAttachmentApi(entityId, attachmentId);
      this.onFileRemove();
    },
  },
  watch: {
    async upload() {
      if (
        !isSuccessfulUpload(this.upload) ||
        !this.upload?.xhrUpload?.headers
      ) {
        // Exit early if upload is not successful or headers are missing
        return;
      }

      const attachmentId =
        this.upload?.xhrUpload?.headers?.[this.attachmentIdKey];
      const entityId = this.upload?.xhrUpload?.headers?.[this.resourceIdKey];
      if (!attachmentId || !entityId) return; // Exit if IDs are missing

      const fnCondition = (result: { status: string }) =>
        result.status !== 'UPLOADED' && !this.cancelPolling;

      const statusFn = async (): Promise<{ status: string }> => {
        if (this.cancelPolling || !this.upload?.xhrUpload?.headers) {
          // Simulate a valid structure to keep TypeScript happy
          return { status: 'CANCELED' };
        }

        try {
          const data = await this.fetchAttachmentApi(entityId, attachmentId);

          if (!data || typeof data !== 'object' || !('status' in data)) {
            // Fallback structure for invalid API response
            return { status: 'UNKNOWN' };
          }

          const { status, virusScanStatus } = data as {
            status: string;
            virusScanStatus?: string;
          };

          if (
            virusScanStatus === 'UNKNOWN' ||
            virusScanStatus === 'IN_PROGRESS'
          ) {
            this.scanning = true;
          }

          if (virusScanStatus === 'INFECTED' && status === 'DELETED') {
            this.scanning = false;
            this.infected = true;
          }

          if (status === 'UPLOADED') {
            this.scanning = false;
            this.attachmentCompleteEvent(attachmentId);

            setTimeout(() => {
              this.onFileRemove();
            }, 3000);
          }

          return { status };
        } catch (error) {
          console.error('Error fetching attachment API:', error);
          // Return a valid structure on error
          return { status: 'ERROR' };
        }
      };

      // Start polling
      await poll(statusFn, fnCondition, 10000, 30);

      // Final fetch to check upload status
      if (!this.cancelPolling && this.upload?.xhrUpload?.headers) {
        try {
          const data = await this.fetchAttachmentApi(entityId, attachmentId);
          if (!data || data.status !== 'UPLOADED') {
            this.fileUploadError = true;
          }
        } catch (error) {
          console.error('Error fetching final attachment status:', error);
        }
      }
    },
  },
});
